<template>
    <SmartLinkItemComponent custom-tag="div" class="split-content-block-basic" v-if="model" :id="`_${model.system.id}`"
        :component="!model.system.workflow">
        <SmartLinkStandaloneElement :codename="type.elements.content.codename">
            <RichText :value="model.elements.content.value" />
        </SmartLinkStandaloneElement>
        <!-- Desktop Button - Hidden on Mobile -->
        <SmartLinkStandaloneElement custom-tag="div" class="d-none d-lg-block"
            :codename="type.elements.call_to_action.codename">
            <CallToAction custom-class="btn btn-primary btn-sm" v-if="model?.elements?.callToAction?.value?.length > 0"
                v-for="item in pageStore.getListAs<CallToActionModel>(model.elements.callToAction.value)"
                :model="item" />
        </SmartLinkStandaloneElement>
    </SmartLinkItemComponent>
</template>

<script lang="ts" setup>
import { type CallToActionModel, type SplitContentBlockBasicContentModel, contentTypes } from '~/models';

const pageStore = usePageStore();
const type = contentTypes.split_content_block_basic_content;
const props = defineProps<{ model: SplitContentBlockBasicContentModel }>();
</script>